import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { IoIosPeople } from "react-icons/io";
import Loader from "../components/loader";
import "../../src/App.css";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";

const UserTypeBusiness = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [personData, setPersonData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_LIVE_API + "province")
      .then((response) => {
        setProvinces(response.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);
      });
  }, []);

  const handleEdit = () => {
    setIsEditing(true); // Set editMode to true when clicking on "Edit"
  };

  const handleCancel = () => {
    setIsEditing(false); // Set editMode to false when clicking on "Cancel"
  };
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_API_LIVE_ADMIN + `get-employer/${id}`, {
        headers,
      })
      .then((response) => {
        if (response?.data?.data) {
          setPersonData(response?.data?.data);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, id]);

  if (!personData) {
    return null; // Return null or a loading state until data is available
  }

  const handleGoBack = () => {
    navigate("/employers");
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z\s]+$/,
        "First Name must contain only letters and spaces"
      )
      .max(50, "First Name must be at most 50 characters")
      .required("First Name is required"),
    surname: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Surname must contain only letters and spaces")
      .max(50, "Surname must be at most 50 characters")
      .required("Surname is required"),
    initials: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "initials must contain only letters and spaces")
      .max(50, "initials must be at most 50 characters")
      .required("Surname is required"),
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    registrationNo: Yup.string()
      .matches(
        /^\d{4}\/\d{6}\/\d{1,2}$/,
        "Invalid registration number format. Use YYYY/MMNNNNN/NC format."
      )
      .required("Please enter registration no."),
    uifNumber: Yup.string().matches(/^$|^[a-zA-Z0-9]+$/, "Invalid UIF number."),
    sa_id_number: Yup.string()
      .required("SA ID number is required")
      .matches(/^[0-9]{13}$/, "Invalid SA ID number"),
    businessName: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z\s]+$/,
        "Business Name must contain only letters and spaces"
      )
      .max(50, "Business Name must be at most 50 characters")
      .required("Please enter business name"),
    provinceId: Yup.string().trim().required("Province Id is required"),
    lineOne: Yup.string().trim().required("This Field Is Required"),
    lineTwo: Yup.string().trim(),
    suburb: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Suburb must contain only letters and spaces")
      .max(50, "Suburb must be at most 50 characters")
      .required("Suburb is required"),
    postalCode: Yup.string()
      .trim()
      .matches(/^[0-9]+$/, "Postal Code must contain only numeric digits")
      .required("Postal Code is required")
      .min(4, "Postal Code must be exactly 4 digits")
      .max(4, "Postal Code must be exactly 4 digits"),
    town: Yup.string()
      .required("Town Name is required") // Town Name cannot be empty
      .matches(
        /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
        "Town Name must contain only letters and single spaces"
      )
      .max(32, "Town Name must be at most 50 characters"),
  });

  const updateDetails = async (values) => {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      await axios.put(
        process.env.REACT_APP_API_LIVE_ADMIN + "update-employer",
        values,
        { headers }
      );

      // Fetch the updated data after updating the details
      const response = await axios.get(
        process.env.REACT_APP_API_LIVE_ADMIN + `get-employer/${id}`,
        { headers }
      );

      if (response?.data?.data) {
        setPersonData(response.data.data);
        setIsEditing(false); // Disable editing mode after successful update
        toast.success("Details updated successfully");
      }
    } catch (error) {
      toast.error("Error updating details");
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString(); // Format the date into a string
  };

  const handleSubmit = async (values) => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    try {
      await validationSchema.validate(values, { abortEarly: false });
      await updateDetails(values);
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      toast.error("Please fix the errors in the form.");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="flex h-full w-full items-center justify-center pt-10 pb-24">
          <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white overflow-hidden">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="flex h-full w-full items-center justify-center pt-10 pb-24">
            <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white overflow-hidden">
              {isEditing ? (
                <div className="w-full">
                  <div className="m-8 my-20 max-w-[500px] mx-auto">
                    <Formik
                      initialValues={personData}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({ setFieldValue }) => (
                        <Form>
                          <div className="mb-8">
                            <div className="flex justify-center mb-3">
                              <div>
                                <div className="flex flex-wrap justify-center gap-1 items-center">
                                  <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 rounded-2xl">
                                    <div className="flex justify-around">
                                      <button
                                        type="button"
                                        style={{
                                          color: "rgb(255, 244, 229)",
                                          backgroundColor: "rgb(254, 201, 15)",
                                        }}
                                        className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
                                      >
                                        <IoIosPeople />
                                      </button>
                                      <p className="mt-3 pl-3">
                                        <span className="text-4xl font-semibold text-right">
                                          {personData.employee_count}
                                        </span>
                                      </p>
                                    </div>
                                    <p className="text-lg text-center text-gray-400 mt-5 pl-3">
                                      Employees
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col border-1 rounded-lg bg-white dark:bg-neutral-700 md:max-w-xl md:flex-row">
                              {/*<img
                              className="h-40 w-40 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg m-2 p-1"
                              src={personData.profileImage}
                              alt="Person"
                            /> */}
                              <div className="flex flex-col justify-center p-5 overflow-hidden">
                                <div className="flex items-center p-1">
                                  <p className="w-1/3 text-gray-600">
                                    Firstname:
                                  </p>
                                  <Field
                                    type="text"
                                    name="firstname"
                                    className="w-2/3 border rounded p-1"
                                  />
                                </div>
                                <ErrorMessage
                                  name="firstname"
                                  component="p"
                                  className="text-red-500"
                                />
                                <div className="flex items-center p-1">
                                  <p className="w-1/3 text-gray-600">
                                    Surname:
                                  </p>
                                  <Field
                                    type="text"
                                    name="surname"
                                    className="w-2/3 border rounded p-1"
                                  />
                                </div>
                                <ErrorMessage
                                  name="surname"
                                  component="p"
                                  className="text-red-500"
                                />
                                <div className="flex items-center p-1">
                                  <p className="w-1/3 text-gray-600">
                                    Initials:
                                  </p>
                                  <Field
                                    type="text"
                                    name="initials"
                                    className="w-2/3 border rounded p-1"
                                  />
                                </div>
                                <ErrorMessage
                                  name="initials"
                                  component="p"
                                  className="text-red-500"
                                />
                                <div className="flex items-center p-1">
                                  <p className="w-1/3 text-gray-600">
                                    Mobile Number:
                                  </p>
                                  <Field
                                    type="text"
                                    name="mobileNumber"
                                    className="w-2/3 border rounded p-1"
                                    maxLength={10}
                                  />
                                </div>
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="p"
                                  className="text-red-500"
                                />
                                <div className="flex items-center p-1">
                                  <p className="w-1/3 text-gray-600">UIF No:</p>
                                  <Field
                                    type="text"
                                    name="uifNumber"
                                    className="w-2/3 border rounded p-1"
                                    maxLength={13}
                                    minLength={13}
                                  />
                                </div>
                                <ErrorMessage
                                  name="uifNumber"
                                  component="p"
                                  className="text-red-500"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="space-y-4">
                            <div className="flex flex-col justify-start p-6 w-full rounded-lg bg-white border-1 dark:bg-neutral-700 md:max-w-xl md:flex-col">
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">
                                  Registration No:
                                </p>
                                <Field
                                  type="text"
                                  name="registrationNo"
                                  className="w-1/2 border rounded p-1"
                                  maxLength={14}
                                  minLength={13}
                                />
                              </div>
                              <ErrorMessage
                                name="registrationNo"
                                component="p"
                                className="text-red-500"
                              />
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">SA ID:</p>
                                <Field
                                  type="text"
                                  name="sa_id_number"
                                  className="w-1/2 border rounded p-1"
                                  maxLength={13}
                                />
                              </div>
                              <ErrorMessage
                                name="sa_id_number"
                                component="p"
                                className="text-red-500"
                              />
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">
                                  Business Name:
                                </p>
                                <Field
                                  type="text"
                                  name="businessName"
                                  className="w-1/2 border rounded p-1"
                                />
                              </div>
                              <ErrorMessage
                                name="businessName"
                                component="p"
                                className="text-red-500"
                              />

                              <div className="flex items-center justify-center p-1">
                                <p
                                  className="w-1/3 text-gray-600"
                                  htmlFor="provinceId"
                                >
                                  Province Id :
                                </p>
                                <Field
                                  as="select"
                                  id="provinceId"
                                  name="provinceId"
                                  onChange={(e) => {
                                    setFieldValue("provinceId", e.target.value);
                                  }}
                                  className="w-1/2 border rounded p-1"
                                >
                                  <option value="">Select a province</option>
                                  {provinces.map((province) => (
                                    <option
                                      key={province.id}
                                      value={province.id}
                                      className="bg-white text-gray-800"
                                    >
                                      {province.province} - {province.id}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <ErrorMessage
                                name="provinceId"
                                component="p"
                                className="text-red-500"
                              />

                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">
                                  Line One :
                                </p>
                                <Field
                                  type="text"
                                  name="lineOne"
                                  className="w-1/2 border rounded p-1"
                                />
                              </div>
                              <ErrorMessage
                                name="lineOne"
                                component="p"
                                className="text-red-500"
                              />
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">
                                  Line Two :
                                </p>
                                <Field
                                  type="text"
                                  name="lineTwo"
                                  className="w-1/2 border rounded p-1"
                                />
                              </div>
                              <ErrorMessage
                                name="lineTwo"
                                component="p"
                                className="text-red-500"
                              />
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">Town :</p>
                                <Field
                                  type="text"
                                  name="town"
                                  className="w-1/2 border rounded p-1"
                                />
                              </div>
                              <ErrorMessage
                                name="town"
                                component="p"
                                className="text-red-500"
                              />
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">Suburb :</p>
                                <Field
                                  type="text"
                                  name="suburb"
                                  className="w-1/2 border rounded p-1"
                                />
                              </div>
                              <ErrorMessage
                                name="suburb"
                                component="p"
                                className="text-red-500"
                              />
                              <div className="flex items-center justify-center p-1">
                                <p className="w-1/3 text-gray-600">
                                  Postal Code :
                                </p>
                                <Field
                                  type="text"
                                  name="postalCode"
                                  className="w-1/2 border rounded p-1"
                                  maxLength={4}
                                />
                              </div>
                              <ErrorMessage
                                name="postalCode"
                                component="p"
                                className="text-red-500"
                              />
                            </div>
                            <div className="flex justify-center items-center">
                              <button
                                type="submit"
                                className="px-4 py-2 bg-[#03C9D7] w-1/2 text-white rounded mr-2"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="px-4 py-2 bg-red-500 w-1/2 text-white rounded mr-2"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              ) : (
                <>
                  <div className="bg-[#03C9D7] text-white font-semibold text-xl p-4 mb-4 rounded-lg shadow-md text-center">
                    {personData.userType}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[
                      { label: "Name", value: personData.name || "-" },
                      { label: "Initials", value: personData.initials || "-" },
                      {
                        label: "Mobile Number",
                        value: personData.mobileNumber || "-",
                      },
                      {
                        label: "Business Name",
                        value: personData.businessName || "-",
                      },
                      {
                        label: "Registration No",
                        value: personData.registrationNo || "-",
                      },
                      {
                        label: "Email Address",
                        value: personData.emailAddress || "-",
                      },
                      {
                        label: "SA ID Number",
                        value: personData.sa_id_number || "-",
                      },
                      {
                        label: "UIF Number",
                        value: personData.uifNumber || "-",
                      },
                      { label: "Address", value: personData.address || "-" },
                      {
                        label: "Created At",
                        value: formatDate(personData.createdAt || "-"),
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        className="bg-gray-100 p-4 rounded-lg shadow-md"
                      >
                        <div className="font-semibold text-lg text-gray-800 mb-2">
                          {field.label}
                        </div>
                        <div className="text-gray-700">{field.value}</div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-between mt-5">
                    <button
                      onClick={handleGoBack}
                      className="px-4 py-2 bg-gray-300 text-gray-800 font-medium rounded-full shadow-md hover:bg-gray-400"
                    >
                      Back
                    </button>
                    <button
                      onClick={handleEdit}
                      className="px-4 py-2 bg-[#03C9D7] text-white font-medium rounded-full shadow-md"
                    >
                      Edit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTypeBusiness;
