import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../components/loader";
import { useNavigate } from "react-router-dom";

export const CalendarComponent = () => {
  const [holidays, setHolidays] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(-1);
  const [editedHoliday, setEditedHoliday] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  const handleUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (file) {
      const allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      // const allowedFileName = "_public_Holiday_SA.xlsx";

      //  && file.name === allowedFileName
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          const readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];

          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

          const headers = dataParse[0];
          const holidayData = [];
          dataParse.slice(1).forEach((row, index) => {
            const obj = {};
            row.forEach((cell, index) => {
              const header = headers[index];
              if (index === 0 && typeof cell === "number")
                cell = new Date((cell - (25567 + 2)) * 86400 * 1000)
                  .toISOString()
                  .split("T")[0];
              if (index < 2) obj[header] = cell;
            });
            if (JSON.stringify(obj)?.length > 2) {
              obj.id = index + 1;
              holidayData.push(obj);
            }
          });

          setHolidays(holidayData);
          uploadHolidays(holidayData);
        };
        reader.readAsBinaryString(file);
      } else {
        toast.error("Invalid file type or file name!");
      }
    } else {
      toast.error("No file selected!");
    }
  };

  const uploadHolidays = (data) => {
    const headers = { Authorization: `Bearer ${token}` };

    if (data.length > 0) {
      const formattedData = data.map((holiday) => ({
        ...holiday,
        id: holiday.id.toString(),
      }));

      axios
        .post(
          process.env.REACT_APP_API_LIVE_API + "add-holiday",
          formattedData,
          {
            headers,
          }
        )
        .then((response) => {
          toast(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
          if (error?.response?.status === 404) {
            toast.error(error?.response?.data[0]);
          } else {
            if (
              error?.response?.data?.error?.response?.message === "Unauthorized"
            ) {
              localStorage.clear();
              navigate("/login");
            } else toast.error("Page not found!");
          }
        });
    }
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
    setEditedHoliday({ ...holidays[index] });
  };

  const handleSave = (data) => {
    const updatedHolidays = [...holidays];
    updatedHolidays[editRowIndex] = editedHoliday;
    setHolidays(updatedHolidays);
    setEditRowIndex(-1);
    setEditedHoliday({});

    const updatedFields = {
      id: data._id,
      date: editedHoliday.date,
      title: editedHoliday.title,
    };

    const headers = { Authorization: `Bearer ${token}` };

    axios
      .patch(
        process.env.REACT_APP_API_LIVE_API + "update-holiday",
        updatedFields,
        { headers }
      )
      .then((response) => {
        const responseBody = {
          ...response.data.data,
          id: response.data.data.id,
        };
        setLoading(false);
        toast(responseBody.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error("error", error);
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data[0]);
        } else {
          if (
            error?.response?.data?.error?.response?.message === "Unauthorized"
          ) {
            localStorage.clear();
            navigate("/login");
          } else toast.error("Page not found!");
        }
      });
  };

  const handleCancel = () => {
    setEditRowIndex(-1);
    setEditedHoliday({});
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedHoliday((prevHoliday) => ({
      ...prevHoliday,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setEditedHoliday((prevHoliday) => ({
      ...prevHoliday,
      date: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const currentYear = new Date().getFullYear();
  const minSelectableDate = new Date(`01-01-${currentYear}`);

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      holidays.map((holiday) => ({
        date: holiday.date,
        title: holiday.title,
      }))
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "Holidays");
    const filePath = process.env.PUBLIC_URL + "/public/Holiday_SA.xlsx";

    XLSX.writeFile(workbook, filePath);
  };

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };

    axios
      .get(process.env.REACT_APP_API_LIVE_API + "holiday", {
        headers,
      })
      .then((response) => {
        const extractedData = response.data.data;
        setHolidays(extractedData);
        setLoading(false);
        toast(response.data.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error("error", error);
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data[0]);
        } else {
          if (
            error?.response?.data?.error?.response?.message === "Unauthorized"
          ) {
            localStorage.clear();
            navigate("/login");
          } else toast.error("Page not found!");
        }
      });
  }, [token]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-extrabold tracking-tight text-slate-900">
        Holiday List
      </h1>
      <div className="flex justify-evenly mb-8">
        <label className="bg-[#03C9D7] hover:bg-[#03C9D7] text-white font-bold py-2 px-4 rounded-lg cursor-pointer">
          Upload XLSX File
          <input
            type="file"
            accept=".xlsx"
            className="hidden"
            onChange={handleUpload}
          />
        </label>
        <button
          className="bg-[#03C9D7] hover:bg-[#03C9D7] text-white font-bold py-2 px-4 rounded-lg"
          onClick={handleDownload}
        >
          Download Template
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className="border-collapse w-full">
          <thead>
            <tr>
              <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                Date
              </th>
              <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                Title
              </th>
              <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {holidays.map((holiday, index) => (
              <tr key={index}>
                <td className="border shadow-sm px-4 py-2 text-center">
                  {index === editRowIndex ? (
                    <DatePicker
                      selected={new Date(editedHoliday.date)}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="w-full"
                      minDate={minSelectableDate}
                    />
                  ) : (
                    moment(holiday.date).format("D MMMM YYYY")
                  )}
                </td>
                <td className="border shadow-sm px-4 py-2 text-center">
                  {editRowIndex === index ? (
                    <input
                      type="text"
                      name="title"
                      value={editedHoliday.title || ""}
                      onChange={handleEditChange}
                      className="border border-[#03C9D7] px-2 py-1 w-full"
                    />
                  ) : (
                    holiday.title
                  )}
                </td>
                <td className="border shadow-sm px-4 py-2 text-center">
                  {editRowIndex === index ? (
                    <React.Fragment>
                      <button
                        className="bg-[#03C9D7] hover:bg-[#03C9D7] text-white font-bold py-1 px-2 rounded-lg mr-2"
                        onClick={() => handleSave(holiday)}
                      >
                        Save
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-500 text-white font-bold py-1 px-2 rounded-lg"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  ) : (
                    <button
                      className="bg-[#03C9D7] hover:bg-[#03C9D7] text-white font-bold py-1 px-2 rounded-lg"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
