import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCurrencyDollar, BsFillGiftFill } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Button, Pie, SparkLine, Stacked } from "../components";
import Loader from "../components/loader";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

import {
  SparklineAreaData,
  dropdownData,
  ecomPieChartData,
} from "../data/dummy";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Dashboard = () => {
  const { currentColor, currentMode } = useStateContext();
  const [statistics, setStatistics] = useState();
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_API_LIVE_ADMIN + "get-home-list", { headers })
      .then((response) => {
        setStatistics(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.data.status === 422) {
          toast.error(error?.response?.data?.error?.response?.error);
        } else {
          if(error?.response?.data?.error?.response?.message==="Unauthorized"){
            localStorage.clear();
            navigate("/login");
          }
         else toast.error("Page not found!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const statisticsData = [
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: statistics?.employer,
      title: "Employers",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <IoIosPeople />,
      amount: statistics?.employee,
      title: "Employees",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
    {
      icon: <RiMoneyDollarBoxFill />,
      amount: statistics?.loan,
      title: "Loans",
      iconColor: "rgb(228, 106, 118)",
      iconBg: "rgb(255, 244, 229)",

      pcColor: "green-600",
    },
    {
      icon: <BsFillGiftFill />,
      amount: statistics?.gift,
      title: "Gifts",
      iconColor: "rgb(0, 194, 146)",
      iconBg: "rgb(235, 250, 242)",
      pcColor: "red-600",
    },
  ];

  return (
    <div className="mt-24 m-2 md:m-10 p-2 md:p-10">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div><p className="font-bold text-gray-400">Earnings</p><p className="text-2xl">$63,448.78</p></div>
                <button type="button"style={{ backgroundColor: currentColor }}className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"><BsCurrencyDollar /></button>
              </div>
              <div className="mt-6"><Button color="white"bgColor={currentColor}text="Download"borderRadius="10px"/></div>
            </div> */}
            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              {statisticsData?.map((item) => (
                <div
                  key={item.title}
                  className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl border"
                >
                  <div className="flex justify-around">
                    <button
                      type="button"
                      style={{
                        color: item.iconColor,
                        backgroundColor: item.iconBg,
                      }}
                      className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
                    >
                      {item.icon}
                    </button>
                    <p className="mt-3 pl-3">
                      <span className="text-4xl font-semibold text-right">
                        {item.amount}
                      </span>
                    </p>
                  </div>
                  <p className="text-lg text-center text-gray-400 mt-5 pl-3">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-10 flex-wrap justify-center">
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780 border">
              <div className="flex justify-between">
                <p className="font-semibold text-xl">Revenue Updates</p>
                <div className="flex items-center gap-4">
                  <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
                    <span>
                      <GoPrimitiveDot />
                    </span>
                    <span>Expense</span>
                  </p>
                  <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
                    <span>
                      <GoPrimitiveDot />
                    </span>
                    <span>Budget</span>
                  </p>
                </div>
              </div>
              <div className="mt-10 flex gap-10 flex-wrap justify-center">
                <div className=" border-r-1 border-color m-4 pr-10">
                  <div>
                    <p>
                      <span className="text-3xl font-semibold">$93,438</span>
                      <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                        23%
                      </span>
                    </p>
                    <p className="text-gray-500 mt-1">Budget</p>
                  </div>
                  <div className="mt-8">
                    <p className="text-3xl font-semibold">$48,487</p>

                    <p className="text-gray-500 mt-1">Expense</p>
                  </div>

                  <div className="mt-5">
                    <SparkLine
                      currentColor={currentColor}
                      id="line-sparkLine"
                      type="Line"
                      height="80px"
                      width="250px"
                      data={SparklineAreaData}
                      color={currentColor}
                    />
                  </div>
                  <div className="mt-10">
                    <Button
                      color="white"
                      bgColor={currentColor}
                      text="Download Report"
                      borderRadius="10px"
                    />
                  </div>
                </div>
                <div>
                  <Stacked
                    currentMode={currentMode}
                    width="320px"
                    height="360px"
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className=" rounded-2xl md:w-400 p-4 m-3"
                style={{ backgroundColor: currentColor }}
              >
                <div className="flex justify-between items-center ">
                  <p className="font-semibold text-white text-2xl">Earnings</p>

                  <div>
                    <p className="text-2xl text-white font-semibold mt-8">
                      $63,448.78
                    </p>
                    <p className="text-gray-200">Monthly revenue</p>
                  </div>
                </div>

                <div className="mt-4">
                  <SparkLine
                    currentColor={currentColor}
                    id="column-sparkLine"
                    height="100px"
                    type="Column"
                    data={SparklineAreaData}
                    width="320"
                    color="rgb(242, 252, 253)"
                  />
                </div>
              </div>

              <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl md:w-400 p-8 m-3 flex justify-center items-center gap-10">
                <div>
                  <p className="text-2xl font-semibold ">$43,246</p>
                  <p className="text-gray-400">Yearly sales</p>
                </div>

                <div className="w-40">
                  <Pie
                    id="pie-chart"
                    data={ecomPieChartData}
                    legendVisiblity={false}
                    height="160px"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
