import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import "./login.css";
import { Wogo } from "../assets";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const navigate = useNavigate();

  const LoginClick = async (values) => {
    const { username, password } = values;

    const loginData = {
      email: username,
      password: password,
    };

    await axios
      .post(process.env.REACT_APP_API_LIVE_ADMIN + "login", loginData)
      .then((response) => {
        if (response.data.response) {
          localStorage.setItem("authToken", response.data.data.accessToken);
          navigate("/dashboard");
          toast("Login Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast(response.data.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error("error",error);
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data[0]);
        } else {
          toast.error("Page not found!");
        }
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container flex justify-center items-center h-screen">
      <div className="login-box bg-white rounded-lg p-8 shadow-md">
        <div className="logo-container flex justify-center">
          <img src={Wogo} alt="Wogo Logo" className="wogo-logo" />
        </div>
        <h2 className="wl-title text-2xl mb-4">Welcome Back!</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={LoginClick}
        >
          <Form>
            <div className="form-group mt-3">
              <label className="form-label text-gray-700">Username</label>
              <Field
                type="text"
                name="username"
                className="form-input-control mt-1 p-2 border border-gray-300 rounded"
                placeholder="Enter username"
              />
              <ErrorMessage
                name="username"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-group mt-3">
              <div className="flex items-center justify-between">
                <label className="form-label text-gray-700">Password</label>
                <NavLink
                  className="login-link text-blue-500"
                  to="/forgotPassword"
                >
                  Forgot password?
                </NavLink>
              </div>
              <div className="relative">
                <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-input-control mt-1 p-2 border border-gray-300 rounded"
                  placeholder="Enter password"
                />
                <span
                  className="password-toggle-icon mt-1"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
                </span>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-group mt-3">
              <div className="flex items-center">
                <Field
                  type="checkbox"
                  name="rememberMe"
                  className="custom-control-input mt-1"
                  id="customCheck1"
                />
                <label
                  className="custom-control-label ml-2 text-gray-700 mt-2"
                  htmlFor="customCheck1"
                >
                  Remember me
                </label>
              </div>
            </div>
            <div className="text-center mt-4">
              <div className="mt-5">
                <button
                  type="submit"
                  style={{ backgroundColor: "#03C9D7" }}
                  className={`text-white p-3 w-full hover:drop-shadow-xl rounded-md`}
                >
                  Login
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
